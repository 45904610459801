export function searchToObject(search: string | null | undefined, key: string): string | null {
    if (!window.URLSearchParams || !search) return null;

    const sp = new URLSearchParams(search);

    try {
        return sp.get(key);
    } catch (e) {
        return null;
    }
}

export function updateSearch(search: string | null, key: string, value: string | null): string {
    if (!window.URLSearchParams) return "";
    if (!search && !value) return "";

    const sp = new URLSearchParams(search ?? "");

    if (!value) {
        sp.delete(key);
    } else {
        sp.set(key, value);
    }

    return sp.toString();
}
