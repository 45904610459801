import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { searchToObject, updateSearch } from "../utils/LocationSearch";

export function useStateUrl<T>(
    initState: T,
    urlParamKey: string,
    encode: (v: T) => string | null,
    decode: (locationState: string | null) => T,
    shouldUpdate: (oldState: T, newState: T) => boolean,
    shouldUseReplaceCheck?: (v: T) => boolean
): [T, React.Dispatch<React.SetStateAction<T>>] {
    const location = useLocation();
    const t = useState(decode(searchToObject(location.search, urlParamKey)) ?? initState);
    const history = useHistory();

    const updateLocationTimeoutRef = useRef<number | undefined>();

    const state = t[0];
    const setState = t[1];

    const currentStateRef = useRef<T>(state);

    useEffect(() => {
        currentStateRef.current = state;
    }, [state]);

    useEffect(() => {
        if (updateLocationTimeoutRef.current) {
            window.clearTimeout(updateLocationTimeoutRef.current);
            updateLocationTimeoutRef.current = undefined;
        }
        updateLocationTimeoutRef.current = window.setTimeout(() => {
            const locationState = decode(searchToObject(location.search, urlParamKey));

            if (!shouldUpdate(currentStateRef.current, locationState)) {
                return;
            }

            setState(locationState);
        }, 100);
    }, [location]);

    useEffect(() => {
        const locationState = decode(searchToObject(location.search, urlParamKey));

        if (!shouldUpdate(locationState, state)) {
            return;
        }

        const shouldUseReplace = shouldUseReplaceCheck ? shouldUseReplaceCheck(state) : false;

        const newLocation: typeof history.location = {
            ...history.location,
            search: updateSearch(history.location.search, urlParamKey, encode(state)),
        };

        if (shouldUseReplace) {
            history.replace(newLocation);
        } else {
            history.push(newLocation);
        }
    }, [state]);

    return t;
}
