import { AppFeaturesDefinition } from "@3destatepl/types";
import React from "react";
import { AppFeatures } from "../../smart-makieta/src/App.types";
import { parseIntSecure } from "./utils/Parse";

export interface TestingSetup {
    guid: string;
    features: AppFeatures;
}

interface Props {
    setup: TestingSetup;
    setSetup: (reducer: (prev: TestingSetup) => TestingSetup) => void;
    applySetup: () => void;
}

interface Keys {
    booleans: Array<keyof AppFeatures>;
    strings: Array<keyof AppFeatures>;
    numbers: Array<keyof AppFeatures>;
}

const featureFlagsKeys: Keys = Object.entries(AppFeaturesDefinition).reduce<Keys>(
    (keys, [keyRaw, type]) => {
        const key = keyRaw as keyof AppFeatures;

        switch (type) {
            case "boolean":
                keys.booleans.push(key);
                break;

            case "number":
                keys.numbers.push(key);
                break;

            case "string":
                keys.strings.push(key);
                break;
        }
        return keys;
    },
    {
        booleans: [],
        strings: [],
        numbers: [],
    }
);

const Setup: React.FC<Props> = ({ setup, setSetup, applySetup }) => {
    return (
        <div className="_setup">
            <h1>Konfiguracja</h1>

            <TextField label="GUID inwestycji" value={setup.guid} setValue={v => setSetup(p => ({ ...p, guid: v }))} />

            <h2>Feature Flagi</h2>

            {featureFlagsKeys.booleans.map(key => (
                <Checkbox
                    key={key}
                    label={key}
                    value={!!setup.features[key]}
                    setValue={v => setSetup(p => ({ ...p, features: { ...p.features, [key]: v } }))}
                />
            ))}

            {featureFlagsKeys.strings.map(key => (
                <InputText
                    label={key}
                    value={(setup.features[key] as string) ?? ""}
                    setValue={v => setSetup(p => ({ ...p, features: { ...p.features, [key]: v } }))}
                />
            ))}

            {featureFlagsKeys.numbers.map(key => (
                <InputText
                    label={key}
                    value={(setup.features[key] as string) ?? ""}
                    setValue={v => setSetup(p => ({ ...p, features: { ...p.features, [key]: parseIntSecure(v) } }))}
                />
            ))}

            <button className="_setup__button" onClick={() => applySetup()}>
                Ok
            </button>
        </div>
    );
};

export default Setup;

const TextField: React.FC<{ label: string; value: string; setValue: (value: string) => void }> = ({
    label,
    value,
    setValue,
}) => {
    return (
        <div className="_setup__text-field">
            <div className="_setup__text-field__label">{label}</div>
            <input type="text" value={value} onChange={e => setValue(e.target.value)} />
        </div>
    );
};

const Checkbox: React.FC<{ label: string; value: boolean; setValue: (value: boolean) => void }> = ({
    label,
    value,
    setValue,
}) => {
    return (
        <div className={`_setup__checkbox ${value ? "is-checked" : ""}`} onClick={() => setValue(!value)}>
            <div className="_setup__checkbox__box"></div>
            <div className="_setup__checkbox__value">{label}</div>
        </div>
    );
};

const InputText: React.FC<{ label: string; value: string; setValue: (value: string) => void }> = ({
    label,
    value,
    setValue,
}) => {
    return (
        <div className="_setup__text-input">
            <div className="_setup__text-input__label">{label}</div>
            <input type="text" value={value} onChange={e => setValue(e.target.value)} />
        </div>
    );
};
