export function parseIntSecure(value: any) {
    if (value === undefined || value === null) return undefined;

    if (typeof value === "number") {
        return value;
    }

    if (typeof value === "string") {
        try {
            const parsedValue = parseInt(value);
            if (Number.isInteger(parsedValue) && parsedValue.toString() === value) {
                return parsedValue;
            }
        } catch (e) {
            return undefined;
        }
    }

    return undefined;
}

export function parseFloatSecure(value: any, precision?: number) {
    if (value === undefined || value === null) return undefined;

    if (typeof value === "number") {
        return precision !== undefined ? parseFloat(value.toFixed(precision)) : value;
    }

    if (typeof value === "string") {
        value = value.replace(",", ".");
        try {
            const parsedValue = parseFloat(value);
            if (!Number.isNaN(parsedValue)) {
                return precision !== undefined ? parseFloat(parsedValue.toFixed(precision)) : parsedValue;
            }
        } catch (e) {
            return undefined;
        }
    }

    return undefined;
}

export function compareAsInt(value_a: string, value_b: string) {
    const a = parseIntSecure(value_a);
    const b = parseIntSecure(value_b);

    if (a === undefined && b === undefined) return null;

    if (a === undefined) return 1;
    if (b === undefined) return -1;

    return a - b;
}
