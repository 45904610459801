import React, { useEffect, useState } from "react";
import { useStateUrl } from "./hooks/useStateUrl";

import { SmartMakietaInit } from "../../smart-makieta/src/index";

import Setup, { TestingSetup } from "./Setup";

declare global {
    interface Window {
        SM: {
            init: SmartMakietaInit;
        };
    }
}

type LoadingState = "idle" | "loading" | "completed" | "error";

const App: React.FC = () => {
    const [loadingState, setLoadingState] = useState<LoadingState>("idle");

    const [setup, setSetup] = useStateUrl<TestingSetup>(
        { guid: "", features: {} },
        "state",
        v => JSON.stringify(v),
        v => {
            try {
                return JSON.parse(v ?? "");
            } catch (e) {
                return { guid: "", features: {} };
            }
        },
        (o, n) => o.guid !== n.guid || JSON.stringify(o.features) !== JSON.stringify(n.features),
        () => true
    );

    const [isVisible, setIsVisible] = useState(!setup.guid);
    const [applySetup, setApplySetup] = useState(!!setup.guid);

    useEffect(() => {
        setLoadingState("loading");
        mountExternalScript()
            .then(() => setLoadingState("completed"))
            .catch(() => setLoadingState("error"));

        window.onkeydown = (e: KeyboardEvent) => {
            if (e.key === "." && e.ctrlKey) {
                setIsVisible(p => !p);
            }
        };
    }, []);

    useEffect(() => {
        if (loadingState === "completed" && setup.guid && applySetup) {
            const oldel = document.getElementById("smart-makieta");
            document.body.removeChild(oldel!);
            const newEl = document.createElement("div");
            newEl.id = "smart-makieta";
            document.body.appendChild(newEl);
            (window as any).initResponse = window.SM.init(setup.guid, "smart-makieta", {}, { ...setup.features });

            setApplySetup(false);
        }
    }, [loadingState, setup, applySetup]);

    if (["idle", "pending"].includes(loadingState)) {
        return <div className="overlay">Ładowanie aplikacji...</div>;
    } else if (["error"].includes(loadingState)) {
        return <div className="overlay">Błąd ładowanie aplikacji. Odświez stronę</div>;
    }
    if (!isVisible) return null;

    return (
        <div className="overlay">
            <Setup
                setup={setup}
                setSetup={reducer => setSetup(p => reducer(p))}
                applySetup={() => {
                    setApplySetup(true);
                    setIsVisible(false);
                }}
            />
        </div>
    );
};

async function mountExternalScript() {
    return new Promise<void>((resolve, reject) => {
        const el = document.createElement("script");
        el.src = "https://3destatesmartmakietaemb.z6.web.core.windows.net/staging/js/main.js";
        el.onload = () => resolve();
        el.onerror = () => reject();
        el.onclose = () => reject();

        document.body.appendChild(el);
    });
}

export default App;
